.home {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  align-items: center;
  height: calc(100vh + 63px);
  z-index: 1000;
}

.hero-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.download-resume {
  margin-top: 20px !important;
  color: white;
}

.greeting {
  display: block;
  margin: -63px 20px 0 20px;
}

.hide {
  display: none;
}

.profile {
  border-radius: 50%;
  border: 0.2rem solid #0be779;
}

.img-and-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.social-logos {
  display: flex;
  justify-content: space-evenly;
}

.greeting-text {
  margin: 15px;
}


.name {
  color: rgb(11, 231, 121);
}


.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 400px;
  margin: 0 20px;
}

.links a,
.links img {
  width: 40px;
  height: 40px;
}

.scroll-down-element {
  display: inline-block;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
  animation-fill-mode: forwards;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh);
  width: 100%;
}


@media screen and (min-width: 599px) {
  .profile {
    width: 300px;
    height: 300px;
  }


}

@media screen and (max-width: 599px) {
  .profile {
    width: 200px;
    height: 200px;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    max-width: 400px;
    margin: 5px 10px;
  }

  .greeting-for-phone {
    font-size: 20px;
  }

  .links a,
  .links img {
    width: 30px;
    height: 30px;
  }

  .home {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hello-text {
    margin-top: -250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media screen and (min-height: 599px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 150px;
  }
}

@media screen and (max-height: 599px) and (min-height: 410px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
  }
}

@media screen and (max-height: 409px) {
  .scroll-down {
    display: none;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  40% {
    transform: rotate(9deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.logo {
  position: relative;
  animation-name: example;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

@keyframes example {
  from {
    top: 0px;
  }

  to {
    top: 20px;
  }
}

.logo2 {
  position: relative;
  animation-name: example2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

@keyframes example2 {
  from {
    top: 0px;
  }

  to {
    top: 15px;
  }
}