.projects {
    background-color: #2C323F;
}

.project-link {
    text-align: center;
    color: white;
    text-decoration: none;
    height: 100%;
    transition: 0.2s;
    position: relative;
    background-color: #3b4353;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-link img {
    border-radius: 5px 5px 0 0;
}

.project-link:hover {
    transform: translateY(-10px);
    background-color: #474f63;
}

.project-name {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.live-github {
    bottom: 5px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.live-github a {
    color: white;
    text-decoration: none;
    background-color: #5E6A80;
    padding: 2px 5px;
    border-radius: 5px;
}