.experience {
  position: relative;
  background-color: #2c323f;
}

.experience-card {
  padding: 10px;
  color: white;
  text-decoration: none;
  height: 100%;
  transition: 0.2s;
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.experience-content {
  margin: 0 auto;
  max-width: 1400px;
}

.experience-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}

.experience-list>li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}

.experience-list>li>div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}