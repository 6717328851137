@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root,
body {
  height: 100%;
  margin: 0;
}
@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
  section {
    position: relative;
    padding: 6rem;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 16px;
  }
  section {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background: #272b33;
  color: white;
  font-family: 'Montserrat', sans-serif;
  min-width: 320px;
  overflow-x: hidden;
}
button > span {
  font-family: 'Montserrat', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
section h1 {
  text-align: center;
}

.home {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  align-items: center;
  height: calc(100vh + 63px);
  z-index: 1000;
}

.hero-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.download-resume {
  margin-top: 20px !important;
  color: white;
}

.greeting {
  display: block;
  margin: -63px 20px 0 20px;
}

.hide {
  display: none;
}

.profile {
  border-radius: 50%;
  border: 0.2rem solid #0be779;
}

.img-and-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.social-logos {
  display: flex;
  justify-content: space-evenly;
}

.greeting-text {
  margin: 15px;
}


.name {
  color: rgb(11, 231, 121);
}


.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 400px;
  margin: 0 20px;
}

.links a,
.links img {
  width: 40px;
  height: 40px;
}

.scroll-down-element {
  display: inline-block;
}

.wave-emoji {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: wave;
          animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh);
  width: 100%;
}


@media screen and (min-width: 599px) {
  .profile {
    width: 300px;
    height: 300px;
  }


}

@media screen and (max-width: 599px) {
  .profile {
    width: 200px;
    height: 200px;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    max-width: 400px;
    margin: 5px 10px;
  }

  .greeting-for-phone {
    font-size: 20px;
  }

  .links a,
  .links img {
    width: 30px;
    height: 30px;
  }

  .home {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hello-text {
    margin-top: -250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media screen and (min-height: 599px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 150px;
  }
}

@media screen and (max-height: 599px) and (min-height: 410px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
  }
}

@media screen and (max-height: 409px) {
  .scroll-down {
    display: none;
  }
}

@-webkit-keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  40% {
    transform: rotate(9deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  40% {
    transform: rotate(9deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.logo {
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes example {
  from {
    top: 0px;
  }

  to {
    top: 20px;
  }
}

@keyframes example {
  from {
    top: 0px;
  }

  to {
    top: 20px;
  }
}

.logo2 {
  position: relative;
  -webkit-animation-name: example2;
          animation-name: example2;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes example2 {
  from {
    top: 0px;
  }

  to {
    top: 15px;
  }
}

@keyframes example2 {
  from {
    top: 0px;
  }

  to {
    top: 15px;
  }
}
.navbar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #23252a;
  border-bottom: 3px solid #0be779;
  pointer-events: fill;
}

.navbar a {
  cursor: pointer;
  font-weight: 600;
}

.navlink-wrapper {
  width: 20%;
}

.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

#hamburger-icon {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 0px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#hamburger-icon span:nth-child(1) {
  top: 0px;
}

#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
  top: 9px;
}

#hamburger-icon span:nth-child(4) {
  top: 18px;
}

#hamburger-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

#hamburger-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

#hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#hamburger-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

@media screen and (min-width: 600px) {
  .hamburger {
    display: none;
  }

  .mobilenav {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .navlinks {
    display: none;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}
.mobilenav-wrapper {
  position: absolute;
  top: 63px;
  width: 100%;
  clip: rect(0, 9999px, 9999px, -9999px);
}
.mobilenav {
  background-color: #1a212e;
}
.mobilenav.on {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(0);
}
.mobilenav.off {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(-100%);
}
.mobilenavlinks {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.mobilenavlink {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mobilenavlinks a {
  text-align: left;
  font-size: larger;
}

.about {
  background-color: #2c323f;
}

.about-text {
  width: 100%;
  max-width: 600px;
}

.about-text>ul {
  list-style-type: none;
}

.about-text>ul>li {
  margin-bottom: 10px;
}

.typewriter {
  display: flex;
  align-items: center;
}

.typewriter-start {
  padding-right: 5px;
}

.typewriter>p {
  margin: 0;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

.location-wrapper img {
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: white;
}

.location-wrapper {
  display: flex;
  align-items: center;
  width: 70%;
}

.skills-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.skills {
  position: relative;
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 0 auto;
  width: 90%;
}

.skill-bar-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: #3e4555;
  border-radius: 2px;
  height: 30px;
  margin-bottom: 10px;
}

.skill-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 30px;
  background-color: #0be779;
  border-radius: 2px 0px 0px 2px;
}

@media screen and (min-width: 960px) {
  .about-content {
    display: flex;
    justify-content: space-evenly;
  }

  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 80px;
    height: 30px;
    font-size: 12px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}

@media screen and (max-width: 959px) and (min-width: 600px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 80px;
    height: 30px;
    font-size: 12px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}

@media screen and (max-width: 599px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skills {
    width: 100%;
  }

  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 65px;
    height: 30px;
    font-size: 10px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}
.section-title {
  font-size: 25pt;
  margin-bottom: 10px;
}
.underline {
  border-top: 4px solid #0be779;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
}

.experience {
  position: relative;
  background-color: #2c323f;
}

.experience-card {
  padding: 10px;
  color: white;
  text-decoration: none;
  height: 100%;
  transition: 0.2s;
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.experience-content {
  margin: 0 auto;
  max-width: 1400px;
}

.experience-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}

.experience-list>li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}

.experience-list>li>div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}
.projects {
    background-color: #2C323F;
}

.project-link {
    text-align: center;
    color: white;
    text-decoration: none;
    height: 100%;
    transition: 0.2s;
    position: relative;
    background-color: #3b4353;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-link img {
    border-radius: 5px 5px 0 0;
}

.project-link:hover {
    transform: translateY(-10px);
    background-color: #474f63;
}

.project-name {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.live-github {
    bottom: 5px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.live-github a {
    color: white;
    text-decoration: none;
    background-color: #5E6A80;
    padding: 2px 5px;
    border-radius: 5px;
}
.contact {
  background-color: #2c323f;
}
.contact-form {
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 500px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.email-success {
  text-align: center;
  color: green;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px #3b4353 inset !important;
  caret-color: white;
}
footer {
  background-color: #2c323f;
  padding-bottom: 10px;
}
.copyright {
  text-align: center;
}

.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #39b175;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 15px;
  font-size: 25px;
}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}

